html,
body {width: 100%; height: 100%; font-family: 'NanumGothic-Bold', Arial, Verdana, sans-serif; margin: 0;}

#root {height: max-content;}

.wrapper {width: 100%; height: 100%; background-color: #fff; /*min-height: 600px;*/}
.wrap {position: relative; width: 1600px; height: 100%; margin: 0 auto; }


/* ELPSOFT HOMEPAGE */

.header {height: 80px; width: 1200px; margin: auto;}
.header #header-logo {background: url('../../assets/images/header-logo.png') no-repeat left 50% transparent; width: 800px; height: 80px; display: inline-block; cursor: pointer;}
.header .menu-bar {display: inline-flex; height: 80px; margin: 0px;}
.header .menu-bar li {cursor: pointer; display: inline-block;}
.header .menu-bar #menu1 {background: url('../../assets/images/menu1-gray.png') no-repeat left 50% transparent; width: 90px; height: inherit;}
.header .menu-bar #menu1.active {background: url('../../assets/images/menu1-blue.png') no-repeat left 50% transparent; width: 90px; height: inherit;}
.header .menu-bar #menu2 {background: url('../../assets/images/menu2-gray.png') no-repeat left 50% transparent; width: 90px; height: inherit;}
.header .menu-bar #menu2.active {background: url('../../assets/images/menu2-blue.png') no-repeat left 50% transparent; width: 90px; height: inherit;}
.header .menu-bar #menu3 {background: url('../../assets/images/menu3-gray.png') no-repeat left 50% transparent; width: 90px; height: inherit;}
.header .menu-bar #menu3.active {background: url('../../assets/images/menu3-blue.png') no-repeat left 50% transparent; width: 90px; height: inherit;}
.header .menu-bar #menu4 {background: url('../../assets/images/menu4-gray.png') no-repeat left 50% transparent; width: 90px; height: inherit;}
.header .menu-bar #menu4.active {background: url('../../assets/images/menu4-blue.png') no-repeat left 50% transparent; width: 90px; height: inherit;}

/* Home */
.home {background: url('../images/home.jpg') no-repeat 50% center transparent; width: 1600px; height: 1700px; }

/* Main slide */
.main1 {background: url('../images/main1.png') no-repeat 50% center transparent; width: 1600px; height: 800px; }
.main2 {background: url('../images/main2.png') no-repeat 50% center transparent; width: 1600px; height: 800px; }
.main3 {background: url('../images/main3.png') no-repeat 50% center transparent; width: 1600px; height: 800px; }

/* Main slide custom */
.slick-dots .slick-active button { width: 30px;}

/* Home > partner */
.partner_wrap { position: relative; width: 1200px; height: 250px; margin: auto; padding-bottom: 30px; }
.partner_list { width: 1200px; }
.partner_content { display: table; float: left; width: 1200px; height: 90px; margin-top: 40px; }
.partner1 {background: url('../images/partner1.png') no-repeat 50% center transparent; width: 1200px; height: 90px;}
.partner2 {background: url('../images/partner2.png') no-repeat 50% center transparent; width: 1200px; height: 90px;}

.more-button { cursor: pointer; width: 120px; height: 42px; position: absolute; top: 1844px; left: 1041px; }
.more-button:hover { background-color: #ffffff88; }

.slide_pagination { position: absolute; left: 50%; top: 150px; bottom: 0; list-style: none; margin: 0 10px 0 0; padding: 0; transform: translateX(-50%); }
.slide_pagination .dot { cursor: pointer; display: inline-block; width: 10px; height: 10px; margin: 0 5px; overflow: hidden; background: #ddd; border-radius: 50%; transition: 0.3s; }
.slide_pagination .dot.dot_active { background: #333; width: 26px; border-radius: 5px; }
.slide_pagination .dot span { display: block; width: 100%; height: 100%; }

/* 각 화면 타이틀과 타이틀 텍스트 */
.title {text-align: center; background: linear-gradient(to right, #2377bf, #0a4b84); height: 250px;}
.title .title-text { position: relative; top: 92px; width: 200px; height: 66px; font-family: NotoSansCJKkr; font-size: 45px; font-weight: 500; font-stretch: normal; font-style: normal; line-height: normal; letter-spacing: -0.9px; color: #ffffff; }

/* Introduce */
.introduce {background: url('../images/introduce.jpg') no-repeat 50% center transparent; width: 1600px; height: 2443px;}

/* Branch */
.branch {background: url('../../assets/images/branch.jpg') no-repeat 50% center transparent; width: 1600px; height: 1462px;}

/* Hire */
.hire {background: url('../../assets/images/hire.png') no-repeat 50% center transparent; width: 1200px; height: 751px; margin-left: 200px;}
.hire-front-title {cursor: pointer; background: url('../../assets/images/hire-front-title2.png') no-repeat 50% center transparent; width: 1200px; height: 128px; margin-left: 200px}
.hire-front-title.fold {cursor: pointer; background: url('../../assets/images/hire-front-title1.png') no-repeat 50% center transparent; width: 1200px; height: 128px; margin-left: 200px;}
.hire-front-content {background: url('../../assets/images/hire-front-content.png') no-repeat 50% center transparent; width: 1200px; height: 734px; margin-left: 200px;}
.hire-back-title {cursor: pointer; background: url('../../assets/images/hire-back-title2.png') no-repeat 50% center transparent; width: 1200px; height: 128px; margin-left: 200px;}
.hire-back-title.fold {cursor: pointer; background: url('../../assets/images/hire-back-title1.png') no-repeat 50% center transparent; width: 1200px; height: 128px; margin-left: 200px;}
.hire-back-content {background: url('../../assets/images/hire-back-content.png') no-repeat 50% center transparent; width: 1200px; height: 734px; margin-left: 200px;}
.bottom-margin {height: 151px;}

/* Map */
.map {background: url('../../assets/images/map.jpg') no-repeat 50% center transparent; height: 790px; margin: 60px 0;}

/* Footer */
.footer-wrapper {width: 100%; height: 110px; background-color: #282828}
.footer {background: url('../../assets/images/footer.png') no-repeat 30% top transparent; width: 1200px; height: 110px; display: block; margin: auto;}

/* ELPSOFT HOMEPAGE */
